import React from "react"
import Layout from "../components/layout"
import Countdown from "../components/countdown"

const Attending = props => (
  <Layout>
    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major" style={{ textAlign: `center` }}>
            <div className="attending" style={{ margin: "60px 10px" }}>
              <Countdown />
              <h3 style={{ marginTop: "40px" }}>We'll see you soon!</h3>
            </div>
          </header>
        </div>
      </section>
    </div>
  </Layout>
)

export default Attending
